import { decode_response } from './decode_response';
import pako from 'pako';
import { remove_trailing_quotation_marks } from '../utils';

export function manage_response(xhttp, data, callback, error_callback, response_decoder = decode_response) {
  const dec = response_decoder(xhttp, data);
  const response = dec.response;

  if (data.compression && dec.body) {
    dec.body = JSON.parse(pako.inflate(dec.body, { to: 'string' }));
  }

  const status_code = `${xhttp.status}`;
  if (status_code.startsWith('4') || status_code.startsWith('5')) {
    const message = dec.body?.error || dec.body?.status_message || dec.body;
    const error_message = remove_trailing_quotation_marks(message);
    return error_callback({
      http_status_code: xhttp.status,
      message: error_message,
    });
  }

  if (response.logsessid) {
    globalThis.set_cookie('logsessid', response.logsessid, 7);
  }

  const headers = {};
  xhttp
    .getAllResponseHeaders()
    .trim()
    .split(/[\r\n]+/)
    .forEach((line) => {
      const parts = line.split(': ');
      const name = parts.shift();
      const value = parts.join(': ');

      headers[name.toLowerCase()] = value;
    });
  if (status_code.startsWith('2')) {
    const ret = dec.body || dec.response;
    return callback({
      res: ret,
      http_status_code: status_code,
      headers: headers,
    });
  }

  return error_callback({
    http_status_code: xhttp.status,
    message: `Cannot manage status code ${xhttp.status}`,
  });
}
