import { hide_element, show_element } from './ui';
import { wait } from './utils';
import { show_sticky_toast } from './toast';
import { Exception } from 'handlebars';

const logout_id = 'logout-li';
const create_new_id = 'newdoc-li';
const create_new_anlalysis_id = 'newan-li';
const create_new_other_id = 'newot-li';
const create_new_model_id = 'newmod-li';
const search_bar_id = 'search_in_home';
const newDocument_menu_id = 'newDocument_menu';
const menuAdmin_id = 'admin-li';
const menuAdmin_item = 'menuAdmin';
const languages_menu_id = 'languages_menu';
const user_menu_icon_id = 'user-menu-icon';
const databases_button_id = 'databases-button';

const user_id = 'user-li';
const company_id = 'company-li';
const jobs_id = 'jobs-li';
const import_id = 'import-li';

const all_visible_navbar_elements = () => [
  document.getElementById(create_new_id),
  document.getElementById(create_new_anlalysis_id),
  document.getElementById(create_new_other_id),
  document.getElementById(create_new_model_id),
  document.getElementById(search_bar_id),
  document.getElementById(newDocument_menu_id),
  document.getElementById(menuAdmin_id),
  document.getElementById(company_id),
  document.getElementById(jobs_id),
  document.getElementById(user_id),
  document.getElementById(logout_id),
  document.getElementById(menuAdmin_item),
  document.getElementById(languages_menu_id),
  document.getElementById(user_menu_icon_id),
  document.getElementById(databases_button_id),
  document.getElementById(import_id),
];

const genome_navbar_elements = () => [
  document.getElementById(logout_id),
  document.getElementById(create_new_id),
  document.getElementById(create_new_anlalysis_id),
  document.getElementById(create_new_other_id),
  document.getElementById(company_id),
  document.getElementById(jobs_id),
  document.getElementById(search_bar_id),
  document.getElementById(user_id),
  document.getElementById(newDocument_menu_id),
  document.getElementById(menuAdmin_item),
  document.getElementById(languages_menu_id),
  document.getElementById(user_menu_icon_id),
  document.getElementById(databases_button_id),
];

const manufacturing_navbar_elements = () => [
  document.getElementById(logout_id),
  document.getElementById(create_new_id),
  document.getElementById(create_new_anlalysis_id),
  document.getElementById(create_new_other_id),
  document.getElementById(company_id),
  document.getElementById(jobs_id),
  document.getElementById(user_id),
  document.getElementById(menuAdmin_item),
  document.getElementById(languages_menu_id),
  document.getElementById(user_menu_icon_id),
  document.getElementById(databases_button_id),
];

const user_navbar_elements = () => [
  document.getElementById(logout_id),
  document.getElementById(company_id),
  document.getElementById(jobs_id),
  document.getElementById(user_id),
  document.getElementById(menuAdmin_item),
  document.getElementById(languages_menu_id),
  document.getElementById(user_menu_icon_id),
  document.getElementById(databases_button_id),
];

const ligo_navbar_elements = () => [
  document.getElementById(logout_id),
  document.getElementById(company_id),
  document.getElementById(jobs_id),
  document.getElementById(user_id),
  document.getElementById(menuAdmin_item),
  document.getElementById(languages_menu_id),
  document.getElementById(user_menu_icon_id),
  document.getElementById(databases_button_id),
  document.getElementById(search_bar_id),
  document.getElementById(import_id),
  document.getElementById(create_new_model_id),
  document.getElementById(newDocument_menu_id),
];

export function hide_navbar_elems() {
  all_visible_navbar_elements().forEach((elem) => hide_element(elem));
}

export function init_genome_navbar() {
  hide_navbar_elems();
  genome_navbar_elements().forEach((elem) => show_element(elem));
  dispatch_event('genome-menu');
}

export function init_manufacturing_navbar() {
  hide_navbar_elems();
  manufacturing_navbar_elements().forEach((elem) => show_element(elem));
  dispatch_event('manufacturing-menu');
}

export function init_user_navbar() {
  hide_navbar_elems();
  user_navbar_elements().forEach((elem) => show_element(elem));
}

export function init_company_navbar() {
  hide_navbar_elems();
  user_navbar_elements().forEach((elem) => show_element(elem));
}

export function init_jobs_navbar() {
  hide_navbar_elems();
  user_navbar_elements().forEach((elem) => show_element(elem));
}

export function init_ligo_navbar() {
  hide_navbar_elems();
  ligo_navbar_elements().forEach((elem) => show_element(elem));
  dispatch_event('report-menu');
}

export function show_login_navbar() {
  hide_navbar_elems();
}

export function show_renew_license_navbar() {
  hide_navbar_elems();
  show_element('navbar_main_div', 'flex');
  document.body.removeAttribute('hidden');
}

export function show_support_navbar() {
  hide_navbar_elems();
  show_element('navbar_main_div', 'flex');
  document.body.removeAttribute('hidden');
}

globalThis.addEventListener('rpc_error', (event) => {
  show_sticky_toast(event.detail['message'], 'Error');
});

globalThis.addEventListener('rpc_error_401', async (event) => {
  await wait(500).then(() => {
    show_login_navbar();
    hide_contents();
    show_element('login_cont', 'flex');
    show_sticky_toast(event.detail['message'], 'Error');
  });
});

globalThis.addEventListener('readonly_document_view', async (event) => {
  await ensure_document_is_loaded(event.detail['response']['_id'] || '');
  const x_lock = event.detail['headers']['x-lock'];
  const reason = `${_(x_lock['function'])} ${_('readonly_document_event')} <b>${x_lock['parent'][1]}</b>`;
  const toast_content = `<p>${reason}</p>`;
  show_sticky_toast(toast_content, _('Operation in progress'));
});

async function ensure_document_is_loaded(doc_id) {
  await globalThis.getElementByIdWithRetry(`${doc_id}_div`);
}
