import { TabulatorFull as Tabulator } from 'tabulator-tables';

import jsoneditor_css from 'jsoneditor/dist/jsoneditor.css';
import tabulator_light_style from 'tabulator-tables/dist/css/tabulator.css';
import { empty_element, hide_element } from './ui';
import { _ } from './translations';
import { show_content } from './contents_visibility';
import { show_jobs_table } from './jobs';

setLightMode();

jsoneditor_css.use();

function setLightMode() {
  tabulator_light_style.use();
}

function show_user_info_doc(user) {
  user.view();
  show_content('user_content');
}

function show_usercompany_doc(usercompany) {
  usercompany.view();
  show_content('company_content');
}

function show_jobs(resp) {
  show_content('jobs_content');
  show_jobs_table(resp)
}

var exclude_properties = ['id', 'customer_id', 'type', '_id', '_rev'];

function show_property_table(dict, target) {
  const properties = globalThis.object_properties(dict, exclude_properties);
  console.log('show property table ');
  const table = new Tabulator('#' + target, {
    height: 300, // set height of table (in CSS or here), this enables the Virtual DOM
    layout: 'fitDataStretch',
    data: properties,
    columns: [
      // Define Table Columns
      {
        title: _('Property'),
        field: 'property',
      },
      {
        title: _('Value'),
        field: 'value',
        formatter: 'textarea',
      },
    ],
  });
}

function closeNav() {
  empty_element('overlay_content_id');
  hide_element('full_overlay');
}

globalThis.set_language = (language) => {
  globalThis.set_cookie('lang', language);
  location.reload();
  return true;
};

function set_current_element_style(element) {
  element.classList.remove('pointer-events-none', 'font-bold', 'bg-gray-200');
  element.classList.add('pointer-events-none', 'font-bold', 'bg-gray-200');
}

export {
  closeNav,
  show_property_table,
  show_user_info_doc,
  show_usercompany_doc,
  show_jobs,
  set_current_element_style,
};
