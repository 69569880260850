import { call } from './crystal_api';
import { get_selected_db_id_clean } from '../hash';
import { object_with_default, wait } from '../utils';

let rpc_cache = {};

const rpc_base = {
  call: (cmd, opt = {}, db, upload, user, password, target_url = globalThis.rpc_config.url) => {
    return new Promise((resolve, reject) =>
      call(target_url, resolve, cmd, opt, upload, user || '', password || '', globalThis.get_cookie('logsessid'), reject, db)
    );
  },
};

const invalidate_cache_regexp = /never invalidate/;
const cacheable_routes = /^data_.*/;

const RETRY_MAX_COUNT = process.env.RETRY_MAX_COUNT || 3;
const BASE_WAIT_TIME = 500;

const rpc_proxy = new Proxy(rpc_base, {
  get: function (rpc, command) {
    if (invalidate_cache_regexp.test(command)) {
      rpc_cache = {};
    }
    return (opt = {}, upload, user, password, should_dispatch_error_events = true, target_url = globalThis.rpc_config.url) => {
      if (command === 'no_op') {
        return Promise.resolve({});
      }
      let selected_db = opt['db'] || get_selected_db_id_clean(); //use given or url db
      const call_id = command + selected_db + JSON.stringify(opt) + upload;
      let cached_result = rpc_cache[call_id];
      if (cached_result) {
        return Promise.resolve(cached_result);
      }

      let rpc_call = function (retry_count) {
        return rpc.call(command, opt, selected_db, upload, user, password, target_url).then(
          (result) => {
            const http_status_code = result.http_status_code;
            const res = result.res;
            const headers = result.headers;
            if (cacheable_routes.test(command)) {
              rpc_cache[call_id] = res;
            }
            let event_code = http_status_code;
            if (http_status_code === '200' && headers['x-lock']) {
              event_code = 'locked';
            }
            if (headers['x-lock']) {
              headers['x-lock'] = JSON.parse(headers['x-lock']);
            }
            should_dispatch_error_events &&
              (event_from_status_code[http_status_code] != 'rpc_error' || headers['x-lock']) &&
              globalThis.dispatchEvent(
                new CustomEvent(event_from_status_code[event_code], {
                  detail: { response: res, headers: headers },
                })
              );
            return Promise.resolve(res);
          },
          async (error) => {
            const is_server_error = `${error.http_status_code}`.startsWith('5');
            if (is_server_error && retry_count < RETRY_MAX_COUNT) {
              await wait(BASE_WAIT_TIME + BASE_WAIT_TIME * retry_count);
              return rpc_call(retry_count + 1);
            }

            should_dispatch_error_events &&
              globalThis.dispatchEvent(
                new CustomEvent(event_from_status_code[error.http_status_code], {
                  detail: error,
                })
              );
            return Promise.reject(error);
          }
        );
      };

      return rpc_call(0);
    };
  },
});

const event_from_status_code = object_with_default(
  {
    202: 'readonly_document_view',
    locked: 'readonly_document_view',
    401: 'rpc_error_401',
  },
  'rpc_error'
);

export { rpc_proxy as rpc };
