import { TabulatorFull as Tabulator } from 'tabulator-tables';

export function show_jobs_table(jobs) {
  const options = {
    layout: 'fitDataStretch',
    placeholder: 'No running job',
    columns: [
      { title: 'Parent document', field: 'parent', width: '15%' },
      { title: 'Running operation', field: 'function', width: '15%' },
      { title: 'Database', field: 'db' },
      { title: 'Job status', field: 'job_status' },
      { title: 'Started', field: 't' },
      { title: 'Running from', field: 'time' },
    ],
    data: [],
  };
  jobs['jobs'].forEach((job) => {
    console.log('job', job);
    options['data'].push({
      parent: job['parent'][1],
      function: job['function'],
      db: job['db'],
      job_status: job['job_status'],
      t: new Date(job['t'] * 1000).toLocaleString(),
      time: running_from(job['t']),
    });
  });
  new Tabulator('#jobs_table_div', options);
}

function running_from(time) {
  const old_date = new Date(time * 1000);
  const now = new Date();
  const ms_diff = now - old_date;
  const s = Math.floor(ms_diff / 1000);
  const m = Math.floor(s / 60);
  const h = Math.floor(m / 60);
  return `${h % 24} hours, ${m % 60} minutes and ${s % 60} seconds`;
}
