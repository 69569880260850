import { set_user_info } from './user';
import { rpc } from './crystal_api/rpc';
import { show_element } from './ui';
import { hide_contents } from './contents_visibility';
import { clean_cookies } from './logout';
import { show_login_navbar } from './navbar';
import { get_databases } from './permission';
import { get_hash } from './hash';

require('../glaze/formulation');

async function login(user, password) {
  return rpc.user_info({}, undefined, user, password).then((ui) => {
    globalThis.user_data.info = ui;
    const new_hash = `#${get_hash(get_databases()).replace(/^#/, '')}`;
    globalThis.history.replaceState({}, '', new_hash);
  });
}

async function load_application(user = '', password = '') {
  const no_authentication_routes = ['#renew_server_license', '#mdf', '#support'];
  const should_skip_authentication = no_authentication_routes.some((r) => location.hash.startsWith(r));

  if (should_skip_authentication) {
    return Promise.resolve();
  }
  const session_id = globalThis.get_cookie('logsessid');

  if (!session_id && !user) {
    return Promise.reject();
  }

  return await rpc.user_info({}, undefined, user, password).then(
    (response) => {
      set_user_info(response);
      hide_contents();
      show_element('navbar_main_div', 'flex');
    },
    (error) => {
      clean_cookies();
      return Promise.reject(error);
    }
  );
}

export function ceramics_genome_page_loaded() {
  load_application().then(globalThis.dispatch_route_changed, () => {
    hide_contents();
    show_login_navbar();
    delete_cookie('last_db');
    show_element('login_cont', 'flex');
  });

  document.body.removeAttribute('hidden');
}

globalThis.login = login;
