import { show_content } from './contents_visibility';
import { rpc } from './crystal_api/rpc';
import { init_user_navbar } from './navbar';
import { TabulatorFull as Tabulator } from 'tabulator-tables';

function instruments_page_content() {
  const instruments_page_template = require(`../view/instruments_page_content.handlebars`);
  const instruments_page_html = instruments_page_template();
  document.getElementById('admin_page_content').insertAdjacentHTML('beforeend', instruments_page_html);
  return true;
}

function render_instruments(instruments) {
  instruments.forEach((inst) => {
    const instrument_element = require(`../view/instruments_page_elements.handlebars`);
    const desc = {
      serial_with_cid: `${inst['customer_id']}_${inst['serial'].replace(' ', '_')}`,
      serial: inst['serial'],
      id: inst['id'],
    };
    const instruments_element_html = instrument_element(desc);
    document.getElementById('instruments-list').insertAdjacentHTML('beforeend', instruments_element_html);
    const instrument_div = document.createElement('div');
    instrument_div.id = `${inst['customer_id']}_${inst['serial'].replace(' ', '_')}_p`;
    const div_title = document.createElement('p');
    div_title.innerText = inst['serial'];
    div_title.style.font = 'bold';
    instrument_div.appendChild(div_title);
    document.getElementById('instruments-list').appendChild(instrument_div);
    const options = {
      layout: 'fitDataStretch',
      columns: [
        { title: 'Param', field: 'param' },
        { title: 'Value', field: 'val' },
      ],
      data: [
        { param: 'Customer', val: `${inst['customer']}, id: ${inst['customer_id']}` },
        { param: 'Functionalities', val: inst['functionalities'].replaceAll(',', ', ') },
        { param: 'Rates', val: inst['rates'] },
      ],
    };
    const table = new Tabulator(instrument_div, options);
  });
}

function show_instruments_page() {
  const admin_page = document.getElementById('admin_page_content');
  empty_element(admin_page);
  instruments_page_content();
  rpc.list_instruments().then((resp) => {
    const ordered_instruments = resp['instruments'].sort((a, b) => a['customer_id'] - b['customer_id']);
    render_instruments(ordered_instruments);
  });
  show_content(admin_page);
  init_user_navbar();
  return true;
}

export { show_instruments_page };
